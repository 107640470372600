<template>
  <div>
    <el-dialog :visible.sync="sta.show" >
      <span slot="title">新开维修单</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>名称</b></label><br/>
            <el-cascader v-model="cat.val" :options="cat.ls" placeholder="请选择分类" :props="{label:'NAME',value:'ID',children:'CHILDREN'}"/>
          </div>
          <div class="form-group">
            <label><b>位置</b></label><br/>
            <el-cascader v-model="loc.val" :options="loc.ls" placeholder="请选择位置" :props="{label:'NAME',value:'ID',children:'CHILDREN'}"/>
          </div>
          <div class="form-group">
            <label><b>问题描述</b></label>
            <el-input type="textarea" :rows="3" v-model="model.DESCRIPTION" />
          </div>
          <div class="form-group">
            <label><b>照片上传</b> <span style="color: red">(注：仅支持单张图片上传)</span></label>
            <el-upload accept="image/*" action="" :limit="1" list-type="picture-card" :auto-upload="false" :on-change="onChange"
                       :file-list="fileList" :on-remove="()=>{model.PIC_B64=''}">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<style></style>
<script>
export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      model: {},
      cat:{
        ls:[],
        val:[]
      },
      loc:{
        ls:[],
        val:[]
      },
      fileList:[]
    }
  },
  methods:{
    init(){
      this.sta={show:true,loading:false}
      this.cat={ls:this.$parent.cat.ls,val:[]};
      this.loc={ls:this.$parent.loc.ls,val:[]};
      this.model={};
      this.fileList=[]; //初始化，清空上传列表，无它用
    },
    onChange(file){
      let self=this;
      this.whale.image.compress({
        file:file.raw,
        callback(cf){
          self.whale.image.toB64({
            file:cf,
            callback(b64){
              self.model.PIC_B64=b64;
            }
          })
        }
      })
    },
    submit(){
      let self=this;
      self.sta.loading=true;
      let n=this.cat.val.length;
      if (n>0) this.model.CAT_ID=this.cat.val[n-1];
      n=this.loc.val.length;
      if (n>0) this.model.LOC_ID=this.loc.val[n-1];

      this.whale.remote.getResult({
        url:"/api/School/FIX/NoteApi/Save",
        data:self.model,
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
